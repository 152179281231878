import React, { createContext, useContext, useState, useEffect } from "react";
import { customAxios } from "./useAxios.js";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import Loader from "components/common/loadingBar";

const AuthContext = createContext();

export const AuthProvider = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  const fetchData = async () => {
    try {
      const response = await customAxios.get("getProfile");
      const userData = response.data?.response;
      if (userData) {
        setUser(userData);
        if (userData.role === "VM_SUPER_ADMIN") {
          navigate(`${process.env.REACT_APP_BASE_ROUTE}/superadmin/company`);
        }
      } else {
        logout();
      }
    } catch (error) {
      logout();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const logout = async () => {
    const baseUrl = window.location.origin.includes("localhost")
      ? "http://localhost:3000/api/v1/"
      : `${window.location.origin}/api/v1/`;

    await fetch(`${baseUrl}/logout`, {
      method: "GET",
      credentials: "include",
    });

    setUser(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("jwt");
    navigate(`${process.env.REACT_APP_BASE_ROUTE}/auth/login`, {
      state: { redirectTo: location.pathname },
    });
  };

  return user ? (
    <AuthContext.Provider value={{ user, logout, reloadUser: fetchData }}>
      {props.children ?? <Outlet />}
    </AuthContext.Provider>
  ) : (
    <Loader />
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
