import React from "react";
import { customAxios } from "hooks/useAxios";
import {
  Button,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CompanyCard from "components/Superadmin/CompanyCard";

export default function Companies() {
  const [companies, setCompanies] = React.useState([]);
  const [error, setError] = React.useState("");

  const searchCompanies = async (e) => {
    e.preventDefault();
    setError("");
    const searchPhrase = e.target.elements.searchPhrase.value.trim();
    try {
      const response = await customAxios.get(
        `companies?searchPhrase=${searchPhrase}`,
      );
      setCompanies(response.data.value);
    } catch (err) {
      setError("Failed to load companies. Please try again.");
    }
  };

  return (
    <Container maxWidth="sm" component={Stack} p={2} spacing={2}>
      <form onSubmit={searchCompanies}>
        <Paper component={Stack} direction="row" p={2} spacing={2}>
          <TextField
            name="searchPhrase"
            variant="outlined"
            size="small"
            placeholder="Enter company name or website"
            required
            inputProps={{ minLength: 3 }}
            sx={{ flex: 1 }}
          />
          <Button variant="contained" type="submit">
            Search
          </Button>
        </Paper>
      </form>
      {error && <Typography color="error">{error}</Typography>}
      {companies.length > 0 ? (
        companies.map((company) => (
          <CompanyCard key={company._id} {...company} />
        ))
      ) : (
        <Typography variant="body2" textAlign="center">
          No results found
        </Typography>
      )}
    </Container>
  );
}
