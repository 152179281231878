import React, { useState } from "react";
import {
  IconButton,
  MenuList,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CustomPopover from "./customPopover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

const ThumbnailImg = (props) => {
  const { url, width, height, aspectRatio, onClick } = props;

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width,
        height,
        aspectRatio,
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    >
      <img
        src={url}
        style={{ display: "block", maxHeight: "100%", maxWidth: "100%" }}
      />
    </Paper>
  );
};

const Thumbnail = (props) => {
  const {
    url,
    templateId,
    width,
    height,
    aspectRatio,
    title,
    isPrivate,
    isDocx,
    showOptions = false,
    onClick,
    deleteTemplate,
    downloadTemplate,
    flag
  } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [, setSearchParams] = useSearchParams();
  const [isHovered, setIsHovered] = useState(false);

  const options = [
    {
      title: "Preview",
      onClick: () =>
        setSearchParams((searchParams) => {
          searchParams.delete("preview");
          if (templateId) {
            searchParams.set("preview", templateId);
          }
          return searchParams;
        }),
    },
    ...(isPrivate && !isDocx
      ? [
        {
          title: "Edit",
          onClick: () =>
            navigate(
              `${process.env.REACT_APP_BASE_ROUTE
              }/issuance/create/design/${"certificate"}/${templateId}?type=edit`,
              { state: { templateName: title } },
            ),
        },
      ]
      : []),
    ...(!isPrivate
      ? [
        {
          title: "Customize",
          onClick: () =>
            navigate(
              `${process.env.REACT_APP_BASE_ROUTE
              }/issuance/create/design/${"certificate"}/new?type=create&templateId=${templateId}`,
              { state: { templateName: title } },
            ),
        },
      ]
      : []),
    ...(isDocx
      ? [
        {
          title: "Download",
          onClick: () => downloadTemplate(templateId),
        },
      ]
      : []),
    ...(isPrivate
      ? [
        {
          title: "Delete",
          onClick: () => deleteTemplate(templateId),
        },
      ]
      : []),
  ];

  return showOptions && Boolean(title) ? (
    <Stack
      spacing={0.5}
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ overflow: 'hidden', borderRadius: '4px' }}
    >
      <ThumbnailImg
        url={url}
        width={width}
        height={height}
        aspectRatio={aspectRatio}
        onClick={onClick}
      />
      {title ? (
        <Typography variant="body2" textAlign="center" noWrap title={title}>
          {title}
        </Typography>
      ) : null}
      {flag}
      {showOptions && isHovered && (
        <CustomPopover
          AnchorComponent={(syntax) => (
            <Paper
              {...syntax}
              sx={{
                backgroundColor: isDarkMode ? "#111" : "#f4f2f0",
                position: "absolute",
                top: 4,
                right: 8,
                borderRadius: "100%",
                boxShadow:
                  "2px 2px 10px 3px rgba(0, 0, 0, .05), 0px 0px 5px 1px rgba(0, 0, 0, .1)",
              }}
            >
              <IconButton size="small">
                <MoreVertIcon />
              </IconButton>
            </Paper>
          )}
          PopoverComponent={(syntax) => (
            <MenuList {...syntax} autoFocus={false}>
              {options.map((option) => (
                <MenuItem
                  key={option.title}
                  onClick={() => {
                    option.onClick();
                    setIsHovered(false);
                  }}
                >
                  {option.title}
                </MenuItem>
              ))}
            </MenuList>
          )}
        />
      )}
    </Stack>
  ) : (
    <ThumbnailImg
      url={url}
      width={width}
      height={height}
      aspectRatio={aspectRatio}
      onClick={onClick}
    />
  );
};

export default Thumbnail;
