import React from "react";
import {
  Stack,
  Button,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Avatar,
  Hidden,
  Grid,
  Badge,
  Paper,
} from "@mui/material";
import CustomPopover from "components/common/customPopover";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useAuth } from "hooks/useAuth";
import CustomDialog from "components/common/CustomDialog";
import ResetPasswordForm from "components/auth/ResetPasswordForm";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MySwal from "utils/Swal";
import { customAxios } from "hooks/useAxios";

const BadgedAvatar = () => {
  const { user } = useAuth();
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <Paper elevation={0} sx={{ borderRadius: "50%" }}>
          {user?.VMApprovalStatus === "ONBOARDED" ? (
            <CheckCircleIcon fontSize="small" color="success" />
          ) : (
            <CancelIcon fontSize="small" color="error" />
          )}
        </Paper>
      }
    >
      <Paper
        variant="outlined"
        elevation={0}
        sx={{
          p: 0.25,
          borderRadius: "50%",
          borderColor:
            user?.VMApprovalStatus === "ONBOARDED"
              ? "success.main"
              : "error.main",
        }}
      >
        <Avatar sx={{ width: 34, height: 34 }}>
          &zwj;{user.email[0].toUpperCase()}
        </Avatar>
      </Paper>
    </Badge>
  );
};

export default function ProfileMenu() {
  const { user, logout } = useAuth();

  const verificationRequest = async (e) => {
    e?.preventDefault();
    MySwal.showLoader("Sending request...");
    const response = await customAxios.post("userRequest", {
      type: "VERIFICATION",
    });
    if (response?.status === 200 || response?.status === 201) {
      MySwal.fire({
        title: "Request received!",
        text: "We’ll reach out to you via your registered email with the next steps.",
        icon: "success",
        allowEscapeKey: false,
        showConfirmButton: false,
        timer: 4000,
      });
    }
  };

  return (
    <CustomPopover
      AnchorComponent={(syntax) => (
        <Button {...syntax} variant="text" color="inherit" sx={{ minWidth: 0 }}>
          <Stack spacing={1} direction="row" alignItems="center">
            <BadgedAvatar />
            <Hidden smDown>
              <Stack
                alignItems="flex-start"
                spacing={-0.5}
                sx={{ textTransform: "none" }}
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{ maxWidth: "200px" }}
                >
                  Welcome {user.email.split("@")[0]}!
                </Typography>
                <Typography variant="caption" noWrap sx={{ maxWidth: "200px" }}>
                  {user.companyDisplayName}
                </Typography>
              </Stack>
            </Hidden>
          </Stack>
        </Button>
      )}
      PopoverComponent={(syntax) => (
        <MenuList {...syntax} sx={{ maxWidth: 300 }}>
          <Grid container spacing={2} wrap="noWrap" px={2} py={1}>
            <Grid item xs="auto">
              <BadgedAvatar />
            </Grid>
            <Grid item xs sx={{ minWidth: 0 }}>
              <Stack
                alignItems="flex-start"
                spacing={0}
                sx={{
                  textTransform: "none",
                  whiteSpace: "normal",
                  marginTop: "-1px",
                }}
              >
                {/* &zwj; is there to fix a issue that some letters is not working in form due to attached event on list. */}
                <Typography variant="h6">
                  &zwj;{user.email.split("@")[0]}
                </Typography>
                <Typography variant="caption" color="grey.700">
                  &zwj;{user.email}
                </Typography>
                <Typography
                  variant="caption"
                  fontStyle="italic"
                  lineHeight={1.4}
                  mt={1}
                >
                  &zwj;
                  {user?.VMApprovalStatus === "ONBOARDED"
                    ? "Verified issuer"
                    : "Unverified issuer"}{" "}
                  {user?.VMApprovalStatus !== "ONBOARDED" &&
                  user.role === "VM_COMPANY_ADMIN" ? (
                    <>
                      <a href="#" onClick={verificationRequest}>
                        click here
                      </a>{" "}
                      to raise verification request
                    </>
                  ) : null}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Divider />
          <CustomDialog
            AnchorComponent={(anchorProps) => (
              <MenuItem {...anchorProps}>
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2">&zwj;Reset Password</Typography>
                </ListItemText>
                <ListItemIcon />
              </MenuItem>
            )}
            title="Reset Password"
            Content={(dialogProps) => <ResetPasswordForm {...dialogProps} />}
          />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2">&zwj;Log out</Typography>
            </ListItemText>
          </MenuItem>
        </MenuList>
      )}
    />
  );
}
