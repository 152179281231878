import React from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";

export default function AddEmailForm({
  email,
  setEmail = () => {},
  handleSubmit = () => {},
  isSending,
}) {
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Typography variant="body2" align="center">
          Enter Email Address or WhatsApp Number
        </Typography>
        <TextField
          size="small"
          placeholder="Email Address or WhatsApp Number"
          disabled={isSending}
          value={email}
          onChange={handleEmailChange}
        />
        <Typography
          variant="caption"
          color="grey.600"
          sx={{ fontStyle: "italic" }}
        >
          Note: An OTP will be send to the Email Address / WhatsApp Number you
          provide.
        </Typography>
      </Stack>
      <Stack pt={3} direction="row" justifyContent="center" spacing={2}>
        <Button
          type="submit"
          variant="contained"
          disableElevation
          disabled={isSending || !email}
        >
          {isSending ? "Sending..." : "Send OTP"}
        </Button>
      </Stack>
    </form>
  );
}
