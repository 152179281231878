import React from "react";
import { Chip, Link, Stack, Tooltip, Typography } from "@mui/material";
import Table from "components/Table";
import { customAxios } from "hooks/useAxios.js";
import TableSkeleton from "components/common/TableSkeleton";
import ActionBar from "components/CredentialsTable/ActionBar";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import EditableEmailCell from "./EditableEmailCell";
import EditableWhatsappCell from "./EditableWhatsappCell";
import PrivateCredentialIcon from "components/common/PrivateCredentialIcon";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const columnHelper = createColumnHelper();
const STATUS_MAP = {
  BLOCKCHAINED: { text: "Draft", color: "warning" },
  NOTIFIED: { text: "Sent", color: "success" },
  OUTBOX: { text: "Outbox", color: "default" },
  FAILED: { text: "Failed", color: "error" },
};

export default function CredentialTable(props) {
  const { filter, searchText, isSeeAll, setIsSeeAll } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [docs, setDocs] = React.useState([]);

  const columnDefinitions = [
    columnHelper.accessor((row) => row?.sharedWith?.name || row.fileName, {
      id: "name",
      header: () => "Recipient Name",
      cell: (info) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ width: "200px" }}
        >
          {info?.row?.original?.visibility === "PRIVATE" && (
            <PrivateCredentialIcon />
          )}
          {info?.row?.original?.isRevoked && (
            <Tooltip
              title={`Credential access is revoked for the recipient. Message: ${info?.row?.original?.revokeMessage}`}
            >
              <VisibilityOffOutlinedIcon
                fontSize="small"
                sx={{ opacity: 0.7 }}
              />
            </Tooltip>
          )}
          <Typography variant="h5" noWrap>
            <Link
              href={`${process.env.REACT_APP_BASE_ROUTE}/qr/${info?.row?.original?.displayId}`}
              target="_blank"
              sx={{ textDecoration: "none", cursor: "pointer" }}
            >
              {info.getValue()}
            </Link>
          </Typography>
        </Stack>
      ),
    }),
    ...(filter === "SENT"
      ? [
          columnHelper.accessor((row) => row?.status, {
            id: "notification",
            enableColumnFilter: false,
            header: () => "Notification",
            cell: (info) => {
              const content = [];
              if (info.getValue() === "BLOCKCHAINED") {
                content.push({
                  text: "Not Notified",
                  color: "warning.main",
                  icon: WarningAmberIcon,
                });
              } else if (info.getValue() === "NOTIFIED") {
                if (info.row.original?.sharedWith?.email) {
                  content.push({
                    text: "Email",
                    color: "success.main",
                    icon: DoneIcon,
                  });
                }
                if (info.row.original?.sharedWith?.whatsappNumber) {
                  content.push({
                    text: "WhatsApp",
                    color: "success.main",
                    icon: DoneIcon,
                  });
                }
              }
              return (
                <Stack direction="row" spacing={2}>
                  {content.map((elem) => (
                    <Stack
                      key={elem.text}
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                    >
                      <elem.icon fontSize="small" sx={{ color: elem.color }} />
                      <Typography
                        variant="caption"
                        color={elem.color}
                        sx={{ fontWeight: 500 }}
                      >
                        {elem.text}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              );
            },
          }),
        ]
      : []),
    columnHelper.accessor((row) => row?.sharedWith?.email, {
      id: "email",
      header: () => "Email Address",
      cell: EditableEmailCell,
    }),
    columnHelper.accessor((row) => row?.sharedWith?.whatsappNumber, {
      id: "whatsapp",
      header: () => "WhatsApp Number",
      cell: EditableWhatsappCell,
    }),
    ...(filter === "SENT" || filter === "OUTBOX"
      ? []
      : [
          columnHelper.accessor((row) => row.status, {
            id: "status",
            meta: {
              filterVariant: "multi-select",
            },
            filterFn: "arrIncludesSome",
            header: () => "Status",
            cell: (info) => (
              <Chip
                label={
                  info.row.original?.timeline?.notify?.outbox
                    ? STATUS_MAP["OUTBOX"]?.text
                    : STATUS_MAP[info.getValue()]?.text
                }
                color={
                  info.row.original?.timeline?.notify?.outbox
                    ? STATUS_MAP["OUTBOX"]?.color
                    : STATUS_MAP[info.getValue()]?.color
                }
              />
            ),
          }),
        ]),
    columnHelper.accessor((row) => row?.signer?.signedOn, {
      id: "issued_on",
      meta: {
        filterVariant: "date-range",
      },
      filterFn: "inDateRange",
      header: () => "Issued On",
      cell: (info) => moment(info.getValue()).format("DD-MM-YYYY"),
    }),
    columnHelper.accessor((row) => row?.analytics?.openedOn, {
      id: "opened_on",
      meta: {
        filterVariant: "date-range",
      },
      filterFn: "inDateRange",
      header: () => "Opened On",
      cell: (info) =>
        info.getValue() ? (
          moment(info.getValue()).format("DD-MM-YYYY")
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
    columnHelper.accessor((row) => JSON.stringify(row?.analytics || {}), {
      id: "share_count",
      enableColumnFilter: false,
      header: () => "Total Shares",
      cell: (info) => {
        const rowData = JSON.parse(info.getValue() || "{}");
        const linkedinShareCount = rowData?.share?.linkedin ?? 0;
        const whatsappShareCount = rowData?.share?.whatsapp ?? 0;
        const facebookShareCount = rowData?.share?.facebook ?? 0;
        const twitterShareCount = rowData?.share?.twitter ?? 0;
        let totalShareCount =
          linkedinShareCount +
          whatsappShareCount +
          facebookShareCount +
          twitterShareCount;
        return (
          <Tooltip
            title={`LinkedIn: ${linkedinShareCount}, WhatsApp: ${whatsappShareCount}, Facebook: ${facebookShareCount}, Twitter: ${twitterShareCount}`}
            arrow
          >
            <div style={{ textAlign: "center" }}>{totalShareCount || "0"}</div>
          </Tooltip>
        );
      },
    }),

    columnHelper.accessor((row) => JSON.stringify(row?.analytics || {}), {
      id: "post_click_count",
      enableColumnFilter: false,
      header: () => "Total Post Clicks",
      cell: (info) => {
        const rowData = JSON.parse(info.getValue() || "{}");
        const linkedinClicks = rowData?.postClicks?.linkedin ?? 0;
        const whatsappClicks = rowData?.postClicks?.whatsapp ?? 0;
        const facebookClicks = rowData?.postClicks?.facebook ?? 0;
        const twitterClicks = rowData?.postClicks?.twitter ?? 0;
        const totalClickCount =
          linkedinClicks + whatsappClicks + facebookClicks + twitterClicks;
        return (
          <Tooltip
            title={`LinkedIn: ${linkedinClicks}, WhatsApp: ${whatsappClicks}, Facebook: ${facebookClicks}, Twitter: ${twitterClicks}`}
            arrow
          >
            <div style={{ textAlign: "center" }}>{totalClickCount || "0"}</div>
          </Tooltip>
        );
      },
    }),

    columnHelper.accessor(
      (row) => JSON.stringify(row?.analytics?.verification || {}),
      {
        id: "verification_count",
        enableColumnFilter: false,
        header: () => "Verification Count",
        cell: (info) => {
          let rowData = JSON.parse(info.getValue());
          let totalVerificationCount =
            (rowData?.widget ?? 0) + (rowData?.qr ?? 0);
          return (
            <Tooltip
              title={`QR: ${rowData?.qr ?? 0} Widget: ${rowData?.widget ?? 0}`}
              arrow
            >
              <div style={{ textAlign: "center" }}>
                {totalVerificationCount || "0"}
              </div>
            </Tooltip>
          );
        },
      },
    ),
    columnHelper.accessor((row) => row?.analytics?.ctr, {
      id: "web_click_count",
      enableColumnFilter: false,
      header: () => "Traffic Sent To Website",
      cell: (info) => (
        <div style={{ textAlign: "center" }}>{info.getValue() || "0"}</div>
      ),
    }),
    columnHelper.accessor((row) => row?.analytics?.addedToLinkedinProfileOn, {
      id: "added_to_profile",
      enableColumnFilter: false,
      header: () => "Added To Profile",
      cell: (info) => (
        <div style={{ textAlign: "center" }}>
          {info.getValue() ? "Yes" : "No"}
        </div>
      ),
    }),
    columnHelper.accessor((row) => row?.docInfo?.expiryDate, {
      id: "expired_on",
      meta: {
        filterVariant: "date-range",
      },
      filterFn: "inDateRange",
      header: () => "Expiry Date",
      cell: (info) =>
        info.getValue() ? (
          moment(info.getValue()).format("DD-MM-YYYY")
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
    ...(isSeeAll === "true"
      ? [
          columnHelper.accessor((row) => row?.createdBy?.email, {
            id: "issued_by",
            header: () => "Issued By",
            cell: (info) =>
              info.getValue() ?? <div style={{ textAlign: "center" }}>-</div>,
          }),
          columnHelper.accessor((row) => row?.lastNotifiedBy?.email, {
            id: "last_notified_by",
            header: () => "Last Notified By",
            cell: (info) =>
              info.getValue() ?? <div style={{ textAlign: "center" }}>-</div>,
          }),
        ]
      : []),
    columnHelper.accessor((row) => row?.docTags, {
      id: "labels",
      meta: {
        filterVariant: "multi-select",
      },
      filterFn: "arrIncludesSome",
      header: () => "Labels",
      cell: (info) =>
        info
          .getValue()
          ?.filter(
            (tag) =>
              tag !== "vm_removed_from_all" && tag !== "vm_removod_from_all",
          )?.length ? (
          info
            .getValue()
            .filter(
              (tag) =>
                tag !== "vm_removed_from_all" && tag !== "vm_removod_from_all",
            )
            .map((tag) => <Chip key={tag} label={tag} sx={{ mx: 0.5 }} />)
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
    columnHelper.accessor((row) => row?.skills, {
      id: "skills",
      meta: {
        filterVariant: "multi-select",
      },
      filterFn: "arrIncludesSome",
      header: () => "Skills",
      cell: (info) =>
        info.getValue()?.length ? (
          info
            .getValue()
            .map((skill) => <Chip key={skill} label={skill} sx={{ mx: 0.5 }} />)
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
    // columnHelper.accessor((row) => row.notesContent, {
    //   id: "notes",
    //   header: () => "Notes",
    //   cell: (info) => (
    //     <div style={{ textAlign: "center" }}>{info.getValue() || "-"}</div>
    //   ),
    // }),
    // columnHelper.accessor((row) => row.eligibility?.title, {
    //   id: "eligibility",
    //   header: () => "Eligibility",
    //   cell: (info) => (
    //     <div style={{ textAlign: "center" }}>{info.getValue() || "-"}</div>
    //   ),
    // }),
  ];

  const fetchDocs = async () => {
    setIsLoading(true);
    let response;
    if (filter) {
      response = await customAxios.get(
        `docs/?isSeeAll=${isSeeAll}${
          filter === "SENT"
            ? `&status={"$in":["NOTIFIED","BLOCKCHAINED"]}`
            : filter === "OUTBOX"
            ? ""
            : `&tag=${encodeURIComponent(filter)}`
        }`,
      );
    } else if (searchText) {
      response = await customAxios.get(
        `docs/search?isSeeAll=${isSeeAll}&searchText=${encodeURIComponent(
          searchText,
        )}`,
      );
    }
    if (response?.data) {
      setDocs(
        filter === "OUTBOX"
          ? response.data.docs.filter((row) => row?.timeline?.notify?.outbox)
          : response.data.docs,
      );
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDocs();
  }, [searchText, filter, isSeeAll]);

  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          id="credentials-table"
          columnDefinitions={columnDefinitions}
          data={docs}
          setData={setDocs}
          actionBar={(table) => (
            <ActionBar
              {...{
                table,
                fetchDocs,
                isSeeAll,
                setIsSeeAll,
                showSeeAllToggle:
                  searchText ||
                  filter === "SENT" ||
                  filter === "DRAFT" ||
                  filter === "OUTBOX",
              }}
            />
          )}
        />
      )}
    </>
  );
}
