import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { customAxios } from "hooks/useAxios";
import { useCredulockerAuth } from "hooks/useCredulockerAuth";
import MySwal from "utils/Swal";

export default function CancelSubscribePopup({ onClose }) {
  const { reloadUser } = useCredulockerAuth();

  const cancelSubscription = async () => {
    MySwal.showLoader("Your subscription cancellation is in progress..");
    const response = await customAxios.post(
      "credulocker/subscription/cancel",
      {},
      { attachCredulockerToken: true },
    );

    if (response?.status === 200) {
      onClose();
      reloadUser();
      MySwal.fire({
        text: "Subscription cancelled successfully!",
        allowEscapeKey: false,
        allowOutsideClick: false,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        px={2}
      >
        <Typography variant="h5">Cancel Subscription</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseOutlinedIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Stack spacing={2} px={4} py={2}>
        <Typography variant="body2">
          If you choose to cancel your subscription to Credulocker, please note
          that all uploaded documents will be deleted from our servers 30 days
          after cancellation. To avoid any data loss, we recommend downloading
          and saving your documents before this period expires.
        </Typography>
        <Typography variant="body2">
          If you have any questions or need assistance, feel free to reach out
          to our support team.
        </Typography>
        <Typography variant="body2">
          Thank you for choosing Credulocker!
        </Typography>
        <Box textAlign="right">
          <Button
            variant="contained"
            color="error"
            disableElevation
            onClick={cancelSubscription}
          >
            Confirm Cancellation
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
