import React, { createContext, useContext, useMemo, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import customTheme from "../customTheme.js";

const ThemeModeContext = createContext();

export function ThemeModeProvider({ shadowRootElement, children }) {
  const [mode, setMode] = useState(localStorage.getItem("itscredibleThemeMode") || 'light');

  const theme = useMemo(() => createTheme(customTheme({ shadowRootElement, themeMode: mode })), [mode]);

  const toggleTheme = () => {
    setMode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem("itscredibleThemeMode", newMode);
      return newMode;
    });
  };
  

  return (
    <ThemeModeContext.Provider value={{ mode, toggleTheme }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
}

export const useThemeMode = () => useContext(ThemeModeContext);
