import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import "./App.css";

const isCredulocker = !!document.getElementById("vmAccessDocumentWidget");

if (isCredulocker) {
  const container = document.querySelector("#vmAccessDocumentWidget");
  const shadowContainer = container.attachShadow({ mode: "open" });
  const shadowRootElement = document.createElement("div");
  shadowContainer.appendChild(shadowRootElement);

  const cache = createCache({
    key: "css",
    prepend: true,
    container: shadowContainer
  });

  ReactDOM.createRoot(shadowRootElement).render(
    <BrowserRouter>
      <CacheProvider value={cache}>
        <App isCredulocker={true} shadowRootElement={shadowRootElement} />
      </CacheProvider>
    </BrowserRouter>
  );
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  );
}

reportWebVitals();
