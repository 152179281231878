import React from "react";
import moment from "moment";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { customAxios } from "hooks/useAxios";
import commonHelpers from "utils/commonHelpers";
import CustomPopover from "components/common/customPopover";
import SocialShare from "components/Preview/SocialShare";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useSearchParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import MySwal from "utils/Swal";

export default function CredentialCard({
  _id,
  fileName,
  signer,
  docInfo,
  issuer,
  displayId,
  visibility,
  documentS3Key,
  isRevoked,
  showDelete = false,
  refetch,
  reloadUser,
}) {
  const [, setSearchParams] = useSearchParams();
  const [thumbnail, setThumbnail] = React.useState(false);
  const isPrivate = visibility === "PRIVATE";

  const fetchThumbnail = async () => {
    try {
      const baseUrl = window.location.origin.includes("localhost")
        ? "http://localhost:3000/api/v1/"
        : window.location.origin.includes("itscredible.com")
        ? `${window.location.origin}/api/v1/`
        : window.location.origin.includes("dev1.credulocker.com")
        ? "https://dev1.itscredible.com/api/v1/"
        : "https://portal.itscredible.com/api/v1/";
      const response = await axios.get(
        `${baseUrl}signedDocs/thumbnailByDisplayId/${displayId}`,
      );
      if (response?.data?.response) {
        setThumbnail(response.data.response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (!isPrivate && !isRevoked) {
      fetchThumbnail();
    }
  }, []);

  const handleAddToLinkedInProfile = (e) => {
    e.stopPropagation();
    customAxios.patch(`docs/analytics/${displayId}`, {
      update: {
        $set: {
          "analytics.addedToLinkedinProfileOn": new Date().toISOString(),
        },
      },
    });

    const issueDate = signer?.signedOn ? new Date(signer?.signedOn) : null;
    const expiryDate = docInfo?.expiryDate
      ? new Date(docInfo?.expiryDate)
      : null;
    const linkedInProfileUrl = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${fileName}&organizationName=${
      issuer?.[0]?.websites?.[0] || ""
    }&issueYear=${issueDate?.getFullYear()}&issueMonth=${issueDate?.getMonth()}&expirationYear=${expiryDate?.getFullYear()}&expirationMonth=${expiryDate?.getMonth()}&certUrl=https://portal.itscredible.com/qr/${displayId}&certId=${displayId}`;

    window.open(linkedInProfileUrl, "_blank");
  };

  const handleDownload = async (e) => {
    e.stopPropagation();
    const envPrefix = isPrivate ? "private-" : "";
    const envSuffix =
      window.location.host.split(".")[0].toLowerCase() === "portal" ||
      (window.location.host.includes("credulocker") &&
        !window.location.origin.includes("dev1.credulocker"))
        ? ""
        : "-stage";
    const file = `https://${envPrefix}cdn${envSuffix}.itscredible.com/${
      documentS3Key || `issuer-data${envSuffix}/${_id}.pdf`
    }`;
    await commonHelpers.downloader({
      fileUrl: file,
      fileName: `${fileName}.pdf`,
    });
  };

  function CardChip() {
    if (isRevoked) {
      return (
        <Chip
          color="error"
          icon={<VisibilityOffOutlinedIcon />}
          label="Revoked"
          sx={{
            position: "absolute",
            top: 0,
            right: -16,
            bgcolor: "error.main",
            color: "primary.surface",
          }}
        />
      );
    } else if (
      docInfo?.expiryDate &&
      new Date(docInfo?.expiryDate) < new Date()
    ) {
      return (
        <Chip
          color="error"
          icon={<WarningAmberRoundedIcon />}
          label="Expired"
          sx={{
            position: "absolute",
            top: 0,
            right: -16,
            bgcolor: "error.main",
            color: "primary.surface",
          }}
        />
      );
    } else if (isPrivate) {
      return (
        <Chip
          color="primary"
          icon={<LockOutlinedIcon />}
          label="Private"
          sx={{
            position: "absolute",
            top: 0,
            right: -16,
          }}
        />
      );
    } else {
      return "";
    }
  }

  const deleteCred = async () => {
    const { isConfirmed } = await MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to delete ${fileName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (isConfirmed) {
      const response = await customAxios.delete(
        `credulocker/users/docs/${_id}`,
        {
          attachCredulockerToken: true,
        },
      );

      if (response.status === 200) {
        refetch();
        reloadUser();

        MySwal.fire({
          title: "Successfully Deleted!",
          text: `${fileName} has been deleted!`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <Card sx={{ height: "100%", position: "relative" }}>
      {showDelete && (
        <Paper
          sx={{
            bgcolor: "white.main",
            position: "absolute",
            top: 4,
            right: 4,
            zIndex: 10,
            borderRadius: "100%",
            boxShadow:
              "2px 2px 10px 3px rgba(0, 0, 0, .05), 0px 0px 5px 1px rgba(0, 0, 0, .1)",
          }}
        >
          <IconButton size="small" onClick={deleteCred}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Paper>
      )}
      <CardActionArea
        disableRipple
        sx={{
          height: "100%",
          p: 1.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "stretch",
        }}
        onClick={() => setSearchParams({ preview: displayId })}
      >
        <Box
          position="relative"
          flex={1}
          bgcolor="whitesmoke"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1.5}
          borderRadius="4px"
        >
          {thumbnail ? (
            <CardMedia
              component="img"
              alt={fileName}
              sx={{
                width: "100%",
                objectFit: "contain",
                borderRadius: "4px",
              }}
              image={thumbnail}
            />
          ) : (
            <CardMedia>
              <DescriptionOutlinedIcon
                color="action"
                sx={{ fontSize: "7.5rem" }}
              />
            </CardMedia>
          )}
          <CardChip />
        </Box>
        <CardContent
          component={Stack}
          sx={{ px: 0, pt: 1.5, pb: 0.5 }}
          spacing={1.5}
        >
          <Stack spacing={0.5}>
            <Typography variant="h5" title={fileName} noWrap>
              {fileName}
            </Typography>
            <Typography variant="body2">
              {moment(signer.signedOn).format("DD MMMM YYYY")}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              startIcon={<LinkedInIcon />}
              size="small"
              sx={{ flex: 1 }}
              disableElevation
              disabled={isRevoked}
              onClick={handleAddToLinkedInProfile}
            >
              Add To Profile
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ minWidth: 0 }}
              disabled={isRevoked}
              onClick={handleDownload}
            >
              <DownloadOutlinedIcon />
            </Button>
            <CustomPopover
              AnchorComponent={(syntax) => (
                <Button
                  {...syntax}
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 0 }}
                  disabled={isRevoked}
                >
                  <ShareOutlinedIcon fontSize="small" />
                </Button>
              )}
              PopoverComponent={(syntax) => (
                <Box {...syntax} p={2}>
                  <SocialShare
                    displayId={displayId}
                    userId={signer?.companyUserId}
                  />
                </Box>
              )}
            />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
