import React from "react";
import axios from "axios";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { customAxios, useAxios } from "hooks/useAxios";
import prettyBytes from "pretty-bytes";
import { useCredulockerAuth } from "hooks/useCredulockerAuth";

export default function GetStoragePopup({ onClose }) {
  const { user, reloadUser } = useCredulockerAuth();
  const [data, isLoading] = useAxios("credulocker/plans", {
    attachCredulockerToken: true,
  });
  const [isPaying, setPaying] = React.useState(false);

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const subscribeToStorage = async (planId) => {
    setPaying(true);
    const response = await customAxios.post(
      "credulocker/subscription",
      { planId },
      { attachCredulockerToken: true },
    );

    if (response?.status === 200) {
      const plan = data.find((plan) => plan?.planId === planId);
      const options = {
        key: "rzp_live_qdtPsqPbzkpaE2",
        amount: response.data.amount,
        currency: response.data.currency,
        name: plan?.item?.name,
        description: plan?.item?.description,
        subscription_id: response.data.id,
        handler: async function (response) {
          const baseUrl = window.location.origin.includes("localhost")
            ? "http://localhost:3000/api/v1/"
            : window.location.origin.includes("itscredible.com")
            ? `${window.location.origin}/api/v1/`
            : window.location.origin.includes("dev1.credulocker.com")
            ? "https://dev1.itscredible.com/api/v1/"
            : "https://portal.itscredible.com/api/v1/";

          await axios.post(`${baseUrl}credulocker/subscription/verification`, {
            ...response,
          });
          reloadUser();
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
    setPaying(false);
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        px={2}
      >
        <Typography variant="h5">Get more storage</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseOutlinedIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Stack direction={{ xs: "column", sm: "row" }} spacing={4} p={4}>
        {!isLoading && data.length
          ? data.map((plan) => (
              <Paper
                key={plan?.planId}
                variant="outlined"
                component={Stack}
                p={4}
                alignItems="center"
                spacing={2}
              >
                <Typography variant="body1">{plan?.item?.name}</Typography>
                <Typography variant="h1">₹{plan?.item?.amount / 100} /mo</Typography>
                <Typography variant="caption">
                  {prettyBytes(plan?.notes?.memoryAllowed)} of storage
                </Typography>
                <Button
                  variant={
                    user?.plan?.planId === plan?.planId ? "text" : "contained"
                  }
                  // disabled={user?.plan?.planId === plan?.planId || isPaying}
                  disabled
                  disableElevation
                  onClick={() => subscribeToStorage(plan?.planId)}
                >
                  {user?.plan?.planId === plan?.planId
                    ? "Current Plan"
                    : isPaying
                    ? "Please wait..."
                    : "Coming Soon"}
                </Button>
              </Paper>
            ))
          : null}
      </Stack>
    </Box>
  );
}
