import React from "react";
import { Chip, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function CompanyCard(props) {
  const { _id, name, VMApprovalStatus, websites } = props;
  const isVerified = VMApprovalStatus === "ONBOARDED";
  const navigate = useNavigate();

  return (
    <Paper
      component={Stack}
      p={2}
      direction="row"
      alignItems="center"
      onClick={() =>
        navigate(
          `${process.env.REACT_APP_BASE_ROUTE}/superadmin/company/${_id}`,
        )
      }
      sx={{ cursor: "pointer" }}
    >
      <Stack spacing={0.5} flexGrow={1}>
        <Typography variant="h5">{name}</Typography>
        <Typography variant="body2">
          {websites?.[0] || "Website not available"}
        </Typography>
      </Stack>
      <Chip
        label={isVerified ? "Verified" : "Unverified"}
        color={isVerified ? "success" : "error"}
        aria-label={isVerified ? "Verified Company" : "Unverified Company"}
      />
    </Paper>
  );
}
