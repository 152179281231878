const customTheme = ({ shadowRootElement, themeMode }) => ({
  palette: {
    mode: themeMode || 'light',
    primary: {
      light: "#0fb0d7",
      main: "#0d98ba",
      dark: "#0c89a7",
      surface: themeMode === "dark" ? "#111" : "#f4f2f0",
      border: "#d1d3d4",
    },
    border: {
      main: "#d1d3d4",
    },
    text: {
      main: "#444444",
    },
    white: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    component: "div",
    body2: {
      opacity: 0.85,
    },
    button: {
      textTransform: "capitalize",
    },
    h1: {
      fontSize: "1.4rem",
      fontWeight: "500",
    },
    h2: {
      fontSize: "1.3rem",
      fontWeight: "400",
    },
    h3: {
      fontSize: "1.2rem",
      fontWeight: "500",
    },
    h4: {
      fontSize: "1.1rem",
      fontWeight: "500",
    },
    h5: {
      fontSize: "1rem",
      fontWeight: "500",
    },
    h6: {
      fontSize: "0.9rem",
      fontWeight: "500",
    },
  },
  action: {
    disabledBackground: "red",
    disabled: "red",
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: shadowRootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: shadowRootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: shadowRootElement,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "&.MuiToolbar-regular": {
            minHeight: "54px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
        sizeLarge: {
          fontSize: "1rem",
          padding: "6px 16px",
        },
      },
      variants: [
        {
          props: { variant: "dark" },
          style: {
            background: "#2C2C2C",
            color: "#eeeeee",
            "&:hover": {
              background: "#2C2C2C",
            },
          },
        },
        {
          props: { variant: "dashed" },
          style: {
            border: "2px dashed #d1d3d4",
          },
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "12px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "1rem",
          marginBottom: "0.1rem",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
        indicator: {
          height: "3px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.extra-small": {
            "& .MuiInputBase-input": {
              padding: "3.5px 12px 5.5px",
              "&.MuiInputBase-inputSizeSmall": {
                paddingRight: "32px",
              },
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: `1px solid ${themeMode === "dark" ? "#444" : "#d1d3d4"}`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "auto",
          padding: "3px 0",
          borderRadius: "4px",
          fontWeight: "500",
        },
        colorSuccess: {
          backgroundColor: "#EBF7F3",
          color: "#41AE8F",
        },
        colorError: {
          backgroundColor: "#FAE8EA",
          color: "#D54F5E",
        },
        colorWarning: {
          backgroundColor: "#FFF3CD",
          color: "#904A1E",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          borderColor: "#999999",
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "dashed-outlined" },
          style: {
            border: "2px dashed #d1d3d4",
          },
        },
      ],
    },
  },
});

export default customTheme;
