import React from "react";
import Header from "components/Header/SuperadminHeader";
import { Box } from "@mui/material";

export default function SuperadminLayout(props) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100dvh" }}>
      <Header />
      <Box p={0} sx={{ flexGrow: 1, backgroundColor: "primary.surface" }}>
        {props.children}
      </Box>
    </Box>
  );
}
