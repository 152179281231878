import React from "react";
import {
  Button,
  Chip,
  Container,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { customAxios, useAxios } from "hooks/useAxios";
import { useParams } from "react-router-dom";
import MySwal from "utils/Swal";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import InvoiceForm from "components/Superadmin/InvoiceForm";

export default function Company() {
  const { companyId } = useParams();
  const [data, isLoading, , reloadData] = useAxios(
    `company/${companyId}/details`,
  );
  const company = data?.value || {};
  const isVerified = company?.VMApprovalStatus === "ONBOARDED";

  const toggleVerification = async () => {
    MySwal.showLoader("Processing...");
    await customAxios.put("company/status", {
      companyId,
      status: isVerified ? "APPROVED" : "ONBOARDED",
    });
    reloadData();
    MySwal.close();
  };

  const addCredits = () => {
    MySwal.fire({
      title: "Add Credits",
      input: "number",
      inputPlaceholder: "Credits to be added...",
      inputAttributes: {
        "aria-label": "Credits to be added",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      showLoaderOnConfirm: true,
      preConfirm: async (credits) => {
        await customAxios.put("company/addCredits", {
          companyId: company._id,
          credits,
        });
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Credits Added!",
          text: `${result.value} credits have been added.`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        reloadData();
      }
    });
  };

  const changePrice = () => {
    MySwal.fire({
      title: "Change Price",
      input: "number",
      inputPlaceholder: "New price...",
      inputAttributes: {
        "aria-label": "New price",
        step: ".01",
      },
      showCancelButton: true,
      confirmButtonText: "Change",
      showLoaderOnConfirm: true,
      preConfirm: async (price) => {
        await customAxios.put("company/price", {
          companyId: company._id,
          price,
        });
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Price Changed!",
          text: `Price changed to ${result.value} credits have been added.`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        reloadData();
      }
    });
  };

  const toggleFreePlan = async (e) => {
    MySwal.showLoader("Processing...");
    await customAxios.put("company/plan", {
      companyId,
      plan: e.target.checked ? "FREE" : "",
    });
    reloadData();
    MySwal.close();
  };

  return (
    !isLoading && (
      <Container maxWidth="md">
        <Grid container py={4} spacing={2}>
          <Grid item xs={12}>
            <Paper
              component={Stack}
              p={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack spacing={1} alignItems="flex-start">
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="h4">{company.name}</Typography>
                  <Typography variant="body2">
                    ({company.websites[0]})
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2">Status:</Typography>
                  <Chip
                    label={isVerified ? "Verified" : "Unverified"}
                    color={isVerified ? "success" : "error"}
                    aria-label={
                      isVerified ? "Verified Company" : "Unverified Company"
                    }
                  />
                </Stack>
              </Stack>
              <Button
                variant="contained"
                color={isVerified ? "error" : "success"}
                disableElevation
                onClick={toggleVerification}
              >
                {isVerified ? "Unverify" : "Verify"}
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={-1}
            >
              <Typography variant="bobdy2">Switch to FREE Plan?</Typography>
              <Switch
                size="small"
                checked={company.plan === "FREE"}
                onChange={toggleFreePlan}
              />
            </Stack>
          </Grid>

          {company.plan === "FREE" ? null : (
            <React.Fragment>
              <Grid item xs={6}>
                <Paper
                  component={Stack}
                  p={2}
                  spacing={3}
                  alignItems="flex-start"
                >
                  <Typography variant="body2">Price per Credit: </Typography>
                  <Stack width="100%" alignItems="center" spacing={2}>
                    <Typography variant="h1">
                      ₹ {company?.rate || 45}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      disableElevation
                      startIcon={<EditIcon />}
                      onClick={changePrice}
                    >
                      Change Price
                    </Button>
                  </Stack>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <Paper
                  component={Stack}
                  p={2}
                  spacing={3}
                  alignItems="flex-start"
                >
                  <Typography variant="body2">Credits: </Typography>
                  <Stack width="100%" alignItems="center" spacing={2}>
                    <Typography variant="h1">
                      {company?.credits || 0}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      disableElevation
                      startIcon={<AddIcon />}
                      onClick={addCredits}
                    >
                      Add Credits
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        <Grid item xs={12}>
          <Paper component={Stack} p={2}>
            <InvoiceForm
              data={company.invoiceDetails}
              reloadData={reloadData}
            />
          </Paper>
        </Grid>
      </Container>
    )
  );
}
