import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { Typography } from "@mui/material";
import SuperadminLayout from "layouts/SuperadminLayout";
import Companies from "./Companies";
import Company from "./Company";

function SuperadminRoutes() {
  return (
    <Routes>
      <Route path="company" element={<Companies />} />
      <Route path="company/:companyId" element={<Company />} />
    </Routes>
  );
}

export default function Superadmin() {
  const { user } = useAuth();

  if (user.role !== "VM_SUPER_ADMIN") {
    return (
      <SuperadminLayout>
        <Typography variant="body1" textAlign="center">
          You don&apos;t have permission to view this page.
        </Typography>
      </SuperadminLayout>
    );
  }

  return (
    <SuperadminLayout>
      <SuperadminRoutes />
    </SuperadminLayout>
  );
}
