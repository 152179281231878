import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TabLayout from "layouts/TabLayout";
import { Add, InfoOutlined, Remove } from "@mui/icons-material";
import { useAuth } from "hooks/useAuth";
import { customAxios } from "hooks/useAxios";
import MySwal from "utils/Swal";

export default function BuyCredits() {
  const { user } = useAuth();
  const [qty, setQty] = useState(100);
  const INCREMENTS = [10, 50, 100, 500];
  const rate = user.rate || 45;
  const subTotal = (qty * rate).toFixed(2);
  const gst = (0.18 * parseFloat(subTotal)).toFixed(2);
  const total = (parseFloat(subTotal) + parseFloat(gst)).toFixed(2);

  const adjustQty = (newQty) => {
    if (newQty >= 0 && newQty < 1000000) {
      setQty(newQty);
    }
  };

  const initiatePayment = async () => {
    MySwal.showLoader("Processing...");
    const res = await customAxios.post("payment", {
      amount: total,
      redirectUrl:
        window.location.origin +
        `${process.env.REACT_APP_BASE_ROUTE}/credits/purchaseHistory`,
    });
    if (res?.data?.url) {
      window.location = res.data.url.replace(
        "mercury-t2.phonepe.com",
        "itscredible.com",
      );
    }
  };

  return (
    <TabLayout>
      <Stack p={2} spacing={3}>
        <Typography variant="h4">Buy Credits</Typography>
        <Paper variant="outlined" sx={{ borderColor: "rgba(0,0,0,.2)" }}>
          <Grid container>
            <Grid item xs={8}>
              <Stack
                p={2}
                spacing={2}
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h1">Enter the Quantity</Typography>
                <Stack
                  direction="row"
                  spacing={0.5}
                  pt={1}
                  alignItems="flex-start"
                >
                  <IconButton onClick={() => adjustQty(qty - 1)}>
                    <Remove />
                  </IconButton>
                  <TextField
                    variant="outlined"
                    size="small"
                    helperText="Minimum 100"
                    error={qty < 100}
                    value={qty}
                    onChange={(e) => adjustQty(parseInt(e.target.value || 0))}
                    onKeyUp={(event) => {
                      if (event.key < 48 || event.key > 57) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <IconButton onClick={() => adjustQty(qty + 1)}>
                    <Add />
                  </IconButton>
                </Stack>
                <Stack direction="row" spacing={2}>
                  {INCREMENTS.map((inc) => (
                    <Button
                      key={inc}
                      variant="outlined"
                      onClick={() => adjustQty(qty + inc)}
                    >
                      +{inc}
                    </Button>
                  ))}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  color="grey.800"
                  spacing={0.5}
                >
                  <InfoOutlined />
                  <Typography variant="body2">
                    1 credit is equal to 1 credential
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack px={3} py={4} spacing={2.5} bgcolor="rgba(0,0,0,.2)">
                <Stack pb={1.5} direction="row" justifyContent="space-between">
                  <Typography variant="body1">
                    {qty} &nbsp; X &nbsp; &#8377;{rate}
                  </Typography>
                  <Typography variant="body1">&#8377;{subTotal}</Typography>
                </Stack>
                <Divider />
                <Stack spacing={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">Sub Total</Typography>
                    <Typography variant="body1">&#8377;{subTotal}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1">GST (18%)</Typography>
                    <Typography variant="body1">&#8377;{gst}</Typography>
                  </Stack>
                </Stack>
                <Divider />
                <Stack pt={4} direction="row" justifyContent="space-between">
                  <Typography variant="h1">Total</Typography>
                  <Typography variant="h1">&#8377;{total}</Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
        <Box textAlign="center">
          <Button
            variant="contained"
            disableElevation
            size="large"
            disabled={qty < 100}
            onClick={initiatePayment}
          >
            Pay Now
          </Button>
        </Box>
      </Stack>
    </TabLayout>
  );
}
