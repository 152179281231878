import React, { useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import validator from "validator";
import IndiaStatesWithCodes from "constants/english/IndiaStatesWithCodes.json";
import MySwal from "utils/Swal";
import { customAxios } from "hooks/useAxios";
import { useParams } from "react-router-dom";

export default function InvoiceForm(props) {
  const { data, reloadData } = props;
  const {
    name,
    address,
    city,
    state,
    pincode,
    country,
    phoneCode,
    phoneNumber,
    email,
    GSTIN,
    PAN,
    POS,
    stateCode,
  } = data || {};

  const { companyId } = useParams();

  const [formData, setFormData] = useState({
    name,
    address,
    city,
    state,
    pincode,
    country: country || "India",
    phoneCode: phoneCode || "+91",
    phoneNumber,
    email,
    GSTIN,
    PAN,
    POS,
    stateCode,
  });
  const [errors, setErrors] = useState({});
  const [isEditable, setIsEditable] = useState(!GSTIN && !PAN);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "state") {
      setFormData((prevData) => ({
        ...prevData,
        stateCode: IndiaStatesWithCodes[value] || "",
      }));
    }
  };

  const handleSwitchChange = (e) => {
    setIsEditable(e.target.checked);
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.address) {
      newErrors.address = "Address is required";
      isValid = false;
    }

    if (!formData.city) {
      newErrors.city = "City is required";
      isValid = false;
    }

    if (!formData.state) {
      newErrors.state = "State is required";
      isValid = false;
    }

    if (!formData.pincode) {
      newErrors.pincode = "Pincode is required";
      isValid = false;
    } else if (!validator.isPostalCode(formData.pincode, "IN")) {
      newErrors.pincode = "Invalid Pincode format";
      isValid = false;
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
      isValid = false;
    } else if (!validator.isMobilePhone(formData.phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number";
      isValid = false;
    }

    if (formData.email && !validator.isEmail(formData.email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }
    
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!formData.GSTIN) {
      newErrors.GSTIN = "GSTIN is required";
      isValid = false;
    } else if (!gstinRegex.test(formData.GSTIN)) {
      newErrors.GSTIN = "Invalid GSTIN format";
      isValid = false;
    }

    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!formData.PAN) {
      newErrors.PAN = "PAN is required";
      isValid = false;
    } else if (!panRegex.test(formData.PAN)) {
      newErrors.PAN = "Invalid PAN format";
      isValid = false;
    }

    if (!formData.stateCode) {
      newErrors.stateCode = "State Code is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      MySwal.showLoader("Processing...");
      await customAxios.put("company/invoiceDetails", {
        companyId,
        invoiceDetails: formData,
      });
      reloadData();
      MySwal.close();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">Invoice Details</Typography>
          <FormControlLabel
            sx={{ m: 0 }}
            control={
              <Switch
                size="small"
                checked={isEditable}
                onChange={handleSwitchChange}
              />
            }
            label="Edit Mode"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            disabled={!isEditable}
            fullWidth
            required
            error={!!errors.name}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            disabled={!isEditable}
            fullWidth
            required
            error={!!errors.address}
            helperText={errors.address}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
            disabled={!isEditable}
            fullWidth
            required
            error={!!errors.city}
            helperText={errors.city}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            size="small"
            label="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
            disabled={!isEditable}
            fullWidth
            required
            error={!!errors.state}
            helperText={errors.state}
          >
            {Object.keys(IndiaStatesWithCodes).map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Pincode"
            name="pincode"
            value={formData.pincode}
            onChange={handleChange}
            disabled={!isEditable}
            fullWidth
            required
            error={!!errors.pincode}
            helperText={errors.pincode}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            disabled
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            label="Phone Code"
            name="phoneCode"
            value={formData.phoneCode}
            onChange={handleChange}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            size="small"
            label="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            disabled={!isEditable}
            fullWidth
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            disabled={!isEditable}
            type="email"
            fullWidth
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="GSTIN"
            name="GSTIN"
            value={formData.GSTIN}
            onChange={handleChange}
            disabled={!isEditable}
            fullWidth
            required
            error={!!errors.GSTIN}
            helperText={errors.GSTIN}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="PAN"
            name="PAN"
            value={formData.PAN}
            onChange={handleChange}
            disabled={!isEditable}
            fullWidth
            required
            error={!!errors.PAN}
            helperText={errors.PAN}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="POS"
            name="POS"
            value={formData.POS}
            onChange={handleChange}
            disabled={!isEditable}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="State Code"
            name="stateCode"
            value={formData.stateCode}
            onChange={handleChange}
            fullWidth
            disabled
            error={!!errors.stateCode}
            helperText={errors.stateCode}
          />
        </Grid>
        {isEditable && (
          <Grid item xs={12} textAlign="center">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
}
