import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import ModeNightOutlinedIcon from '@mui/icons-material/ModeNightOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { useThemeMode } from 'hooks/ThemeModeProvider';

export default function ThemeModeSwitch() {
  const { mode, toggleTheme } = useThemeMode();


  return (
    <Tooltip title={`Switch to ${mode === 'dark' ? 'light' : 'dark'} theme`}>
      <IconButton onClick={toggleTheme}>
        {mode === 'dark' ? (
          <LightModeOutlinedIcon />
        ) : (
          <ModeNightOutlinedIcon />
        )}
      </IconButton>
    </Tooltip>
  );
}
