import React from "react";
import { AuthProvider } from "./hooks/useAuth";
import { IssuanceDataProvider } from "./hooks/useIssuanceDataProvider";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { SnackbarProvider } from "notistack";

// UI
import { CssBaseline } from "@mui/material";

// Pages
import RegisterStep1 from "./pages/auth/RegisterStep1";
import RegisterStep2 from "./pages/auth/RegisterStep2";
import Login from "./pages/auth/Login";
import LoginWithOtp from "./pages/auth/LoginWithOtp";
import ForgotPasswordStep1 from "./pages/auth/ForgotPasswordStep1";
import ForgotPasswordStep2 from "./pages/auth/ForgotPasswordStep2";

import DocBox from "./pages/user/DocBox";
import TemplateLibrary from "./pages/issuance/TemplateLibrary";
import UploadExistingCredentials from "./pages/issuance/UploadExistingCredentials";
import Builder from "./components/Builder";
import Settings from "./pages/user/Settings";
import IssuanceSettings from "./pages/issuance/settings";
import AddRecipients from "./pages/issuance/recipients";
import SendCredential from "./pages/issuance/SendCredential";
import Preview from "./components/Preview";
import PurchaseHistory from "pages/credits/PurchaseHistory";
import BuyCredits from "pages/credits/BuyCredits";

import moment from "moment";
import "moment/locale/en-in";
import Joyride from "components/Joyride";
import { TourProvider } from "hooks/useTour";
import ScheduledCreds from "pages/user/ScheduledCreds";
import { CredulockerAuthProvider } from "hooks/useCredulockerAuth";
import Credulocker from "pages/credulocker";
import Superadmin from "pages/superadmin";
import { ThemeModeProvider } from './hooks/ThemeModeProvider';
moment.locale("en-in");


const AuthWrapper = () => {
  if (window.localStorage.getItem("jwt")) {
    return (
      <Navigate
        to={`${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=SENT`}
      />
    );
  }
  return <Outlet />;
};

function ItsCredibleApp() {
  return (
    <>
      <Routes>
        <Route path={process.env.REACT_APP_BASE_ROUTE}>
          <Route
            path="superadmin/*"
            element={
              <AuthProvider>
                <Superadmin />
              </AuthProvider>
            }
          />

          <Route path="user" element={<AuthProvider />}>
            <Route path="home" element={<DocBox />} />
            <Route path="scheduled/:draftId" element={<ScheduledCreds />} />
            <Route path="settings" element={<Settings />} />
          </Route>

          <Route path="credits" element={<AuthProvider />}>
            <Route path="purchaseHistory" element={<PurchaseHistory />} />
            <Route path="buy" element={<BuyCredits />} />
          </Route>

          <Route path="issuance" element={<AuthProvider />}>
            <Route
              path=":issuanceType/design/:type/:id"
              element={<Builder />}
            />
            <Route
              path=":issuanceType/selectTemplate"
              element={<TemplateLibrary />}
            />
            <Route
              path=":issuanceType/uploadCredentials"
              element={
                <IssuanceDataProvider key="1">
                  <UploadExistingCredentials />
                </IssuanceDataProvider>
              }
            />
            <Route
              path=":issuanceType/settings"
              element={
                <IssuanceDataProvider key="2">
                  <IssuanceSettings />
                </IssuanceDataProvider>
              }
            />
            <Route
              path=":issuanceType/recipients"
              element={
                <IssuanceDataProvider key="3">
                  <AddRecipients />
                </IssuanceDataProvider>
              }
            />
            <Route
              path=":issuanceType/send"
              element={
                <IssuanceDataProvider key="4">
                  <SendCredential />
                </IssuanceDataProvider>
              }
            />
          </Route>

          <Route path="qr">
            <Route path=":displayId" element={<Preview />} />
          </Route>

          <Route path="auth" element={<AuthWrapper />}>
            <Route path="login" element={<Login />} />
            <Route path="loginWithOtp" element={<LoginWithOtp />} />
            <Route path="register/step1" element={<RegisterStep1 />} />
            <Route path="register/step2" element={<RegisterStep2 />} />
            <Route
              path="forgotPassword/step1"
              element={<ForgotPasswordStep1 />}
            />
            <Route
              path="forgotPassword/step2"
              element={<ForgotPasswordStep2 />}
            />
          </Route>

          <Route
            index
            path="*"
            element={
              <Navigate
                to={`${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=SENT`}
              />
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <Navigate to={`${process.env.REACT_APP_BASE_ROUTE}`} replace />
          }
        />

        <Route path="qr">
          <Route path=":displayId" element={<Preview />} />
        </Route>

        <Route
          path="*"
          element={
            <Navigate to={`${process.env.REACT_APP_BASE_ROUTE}`} replace />
          }
        />
      </Routes>
      <AppTour />
    </>
  );
}

function AppTour() {
  const location = useLocation();

  return (
    <>
      {location.pathname.startsWith(
        `${process.env.REACT_APP_BASE_ROUTE}/user`,
      ) ||
      location.pathname.startsWith(
        `${process.env.REACT_APP_BASE_ROUTE}/issuance`,
      ) ? (
        <AuthProvider>
          <Joyride />
        </AuthProvider>
      ) : null}
    </>
  );
}

function AppWrapper(props) {
  const { shadowRootElement } = props;
  return (
    <ThemeModeProvider shadowRootElement={shadowRootElement}>
      <TourProvider>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <CssBaseline />

          {props.children}
        </SnackbarProvider>
      </TourProvider>
    </ThemeModeProvider>
  );
}

function App({ isCredulocker, shadowRootElement }) {
  return (
    <AppWrapper shadowRootElement={shadowRootElement}>
      {isCredulocker ? (
        <CredulockerAuthProvider>
          <Credulocker />
        </CredulockerAuthProvider>
      ) : (
        <ItsCredibleApp />
      )}
    </AppWrapper>
  );
}

export default App;
