import React from "react";
import {
  Box,
  Grid,
  Paper,
  Divider,
  IconButton,
  Alert,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import StepProgress from "components/common/StepProgress";
import MySwal from "utils/Swal";
import { useAuth } from "hooks/useAuth";
import { useTheme } from '@mui/material/styles';

export default function IssuanceLayout(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { issuanceType } = useParams();
  const { user } = useAuth();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const stepsInit = [
    {
      id: "design",
      label:
        issuanceType === "existing" ? "Upload Credentials" : "Select Template",
      url:
        issuanceType === "existing"
          ? `${process.env.REACT_APP_BASE_ROUTE}/issuance/${issuanceType}/uploadCredentials`
          : `${process.env.REACT_APP_BASE_ROUTE}/issuance/${issuanceType}/selectTemplate`,
      isCompleted: false,
      isActive: false,
    },
    {
      id: "settings",
      label: "Credential Details",
      url: `${process.env.REACT_APP_BASE_ROUTE}/issuance/${issuanceType}/settings`,
      isCompleted: false,
      isActive: false,
    },
    {
      id: "recipients",
      label: "Recipients",
      url: `${process.env.REACT_APP_BASE_ROUTE}/issuance/${issuanceType}/recipients`,
      isCompleted: false,
      isActive: false,
    },
    {
      id: "send",
      label: "Send",
      url: `${process.env.REACT_APP_BASE_ROUTE}/issuance/${issuanceType}/send`,
      isCompleted: false,
      isActive: false,
    },
  ];

  const [steps, setSteps] = React.useState(stepsInit);

  const handleBack = () => {
    const action = () =>
      navigate(`${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=SENT`);
    if (location.pathname.includes("selectTemplate")) {
      action();
      return;
    }
    MySwal.fire({
      title: "Are you sure you want to leave?",
      // text: "Unsaved changes may be lost.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Leave",
      cancelButtonText: "Cancel",
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        action();
      }
    });
  };

  const handleActive = () => {
    const currentPath = location.pathname.replace(/\/$/, "");
    const tempSteps = steps.map((step, index) => {
      const isCompleted = index < steps.findIndex((s) => s.url === currentPath);
      const isActive = step.url === currentPath;

      return {
        ...step,
        isCompleted,
        isActive,
      };
    });
    setSteps(tempSteps);
  };

  React.useEffect(() => {
    handleActive();
  }, [location]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100dvh" }}>
      <Paper elevation={0}>
        <Box p={1}>
          <Grid container alignItems="center">
            <Grid item sm={2} xs={0}></Grid>
            <Grid item sm={8} xs={10}>
              <StepProgress steps={steps} />
            </Grid>
            <Grid container item xs={2} justifyContent="flex-end">
              <IconButton onClick={handleBack}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Divider />
      {user?.plan !== "FREE" &&
        (!user?.credits || user.credits <= 0 ? (
          <Alert severity="error">
            Credits exhausted.{" "}
            <Link
              component={RouterLink}
              to={`${process.env.REACT_APP_BASE_ROUTE}/credits/buy`}
            >
              Recharge Now
            </Link>
          </Alert>
        ) : user.credits < 100 ? (
          <Alert severity="warning">
            {user.credits} credits remaining. <Link
              component={RouterLink}
              to={`${process.env.REACT_APP_BASE_ROUTE}/credits/buy`}
            >
              Recharge Now
            </Link>
          </Alert>
        ) : (
          ""
        ))}
      <Box
        p={0}
        sx={{
          flexGrow: 1,
          backgroundColor: isDarkMode ? "#111" : "#f4f2f0",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
        data-tut="TemplateLibraryWrapper"
      >
        {props.children}
      </Box>
    </Box>
  );
}
