import React, { useEffect, useRef } from "react";
import Moveable from "react-moveable";
import {
  Box,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import constants from "constants/english/builder";
import { useLocation } from "react-router-dom";

const Canvas = (props) => {
  const {
    selectedElement,
    setSelectedElement,
    dimension,
    backgroundImg,
    setBackgroundImg,
    templateBgColor,
    canvasLoading,
    contentEditing,
  } = props;
  const location = useLocation();

  const selectedElementRef = useRef(selectedElement);
  useEffect(() => {
    selectedElementRef.current = selectedElement;
  }, [selectedElement]);

  const contentEditingRef = useRef(contentEditing);
  useEffect(() => {
    contentEditingRef.current = contentEditing;
  }, [contentEditing]);

  const moveableRef = useRef(null);
  const clipboardDataRef = useRef(null);

  const copyElementToClipboard = (element) => {
    if (!element) return;
    const clonedElement = element.cloneNode(true);
    const tempContainer = document.createElement("div");
    tempContainer.appendChild(clonedElement);
    const elementHtml = tempContainer.innerHTML;
    clipboardDataRef.current = elementHtml;
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = elementHtml;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
  };

  const pasteElementIntoContainer = () => {
    if (!clipboardDataRef.current) return;
    const templateContainer = document.getElementById("template-container");
    const tempElement = document.createElement("div");
    tempElement.innerHTML = clipboardDataRef.current;
    tempElement.firstChild.style.transform = "translate(8px, 8px)";
    const newChild = templateContainer.appendChild(tempElement.firstChild);
    setSelectedElement(newChild);
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      const targetEl = e.target.closest(".selectable");
      const canvasWrapper = e.target.closest("#template-container");
      if (targetEl) {
        setSelectedElement(targetEl);
      } else if (canvasWrapper) {
        setSelectedElement();
      }
    });

    const handleKeyDown = (event) => {
      if (contentEditingRef.current) {
        return;
      }
      if (event.ctrlKey && (event.key === "c" || event.key === "C")) {
        copyElementToClipboard(selectedElementRef.current);
        return;
      }

      if (event.ctrlKey && (event.key === "v" || event.key === "V")) {
        pasteElementIntoContainer();
        return;
      }

      if (selectedElementRef.current && moveableRef.current) {
        const container = document.getElementById("template-container");
        const containerRect = container.getBoundingClientRect();
        const step = 5; // Number of pixels to move per arrow press

        const pxToPercentage = (px, axis) => {
          const containerSize =
            axis === "x" ? containerRect.width : containerRect.height;
          return (px / containerSize) * 100;
        };

        let deltaX = 0;
        let deltaY = 0;

        switch (event.key) {
          case "ArrowUp":
            deltaY = -pxToPercentage(step, "y");
            break;
          case "ArrowDown":
            deltaY = pxToPercentage(step, "y");
            break;
          case "ArrowLeft":
            deltaX = -pxToPercentage(step, "x");
            break;
          case "ArrowRight":
            deltaX = pxToPercentage(step, "x");
            break;
          default:
            return;
        }

        moveableRef.current.request("draggable", { deltaX, deltaY }, true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const Editable = {
    name: "editable",
    props: [],
    events: [],
    render(moveable, React) {
      const rect = moveable.getRect();
      const { pos2 } = moveable.state;
      const EditableViewer = moveable.useCSS(
        "div",
        `{
          position: absolute;
          left: 0px;
          top: 0px;
          will-change: transform;
          transform-origin: 0px 0px;
          cursor: pointer;
        }`,
      );
      return (
        <EditableViewer
          key={"editable-viewer"}
          style={{
            transform: `translate(${pos2[0]}px, ${pos2[1]}px) rotate(${rect.rotation}deg) translate(4px)`,
          }}
        >
          <Paper
            elevation={8}
            square={false}
            sx={{
              borderRadius: "10px",
            }}
            component={Stack}
            direction="row"
            spacing={1}
            py={0.5}
            px={1}
          >
            <Tooltip title="Duplictae" arrow>
              <IconButton
                onClick={() => {
                  copyElementToClipboard(selectedElementRef.current);
                  pasteElementIntoContainer();
                }}
              >
                <CopyAllIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Delete"
              arrow
              onClick={() => {
                selectedElement?.remove();
                setSelectedElement();
              }}
            >
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Paper>
        </EditableViewer>
      );
    },
  };

  return canvasLoading ? (
    <Skeleton
      variant="rectangle"
      width={dimension.width}
      height={dimension.height}
    />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      flexGrow={1}
      bgcolor="primary.surface"
      overflow="hidden"
      height="calc(100dvh - 142px)"
      p={2}
    >
      <Typography variant="body2" mb={1}>
        Dimensions: {Math.round(dimension.width)}px X{" "}
        {Math.round(dimension.height)}px
      </Typography>
      <Box
        position="relative"
        sx={{
          transform: `scale(${1 / dimension.scale})`,
          transformOrigin: "top center",
        }}
      >
        {backgroundImg && !location?.state?.backgroundImg ? (
          <Box
            position="absolute"
            bottom="100%"
            right={0}
            sx={{
              transform: `scale(${dimension.scale})`,
              transformOrigin: "bottom center",
            }}
          >
            <Tooltip title="Remove background image">
              <IconButton size="small" onClick={() => setBackgroundImg()}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
        <Paper
          elevation={2}
          id="template-container"
          mt={1}
          sx={{
            width: dimension.width + "px",
            height: dimension.height + "px",
            backgroundColor: templateBgColor,
          }}
        >
          {backgroundImg ? (
            <img
              src={backgroundImg}
              style={{ width: "100%", height: "100%", userSelect: "none" }}
            />
          ) : null}
          <Moveable
            ref={moveableRef}
            target={selectedElement}
            ables={[Editable]}
            props={{
              editable: true,
            }}
            origin={false}
            draggable={true}
            throttleDrag={0}
            onDrag={({ target, left, top }) => {
              target.style.left = `${(left / dimension.width) * 100}%`;
              target.style.top = `${(top / dimension.height) * 100}%`;
            }}
            resizable={true}
            keepRatio={selectedElement?.tagName === "IMG"}
            renderDirections={["se", "s", "e"]}
            throttleResize={0}
            onResize={({ target, width, height, delta }) => {
              if (width > 1) {
                delta[0] && (target.style.width = `${width}px`);
              }
              if (height > 1) {
                delta[1] && (target.style.height = `${height}px`);
              }
            }}
            snappable={true}
            snapDirections={{
              top: true,
              left: true,
              bottom: true,
              right: true,
              middle: true,
              center: true,
            }}
            elementSnapDirections={{
              top: true,
              left: true,
              bottom: true,
              right: true,
              center: true,
              middle: true,
            }}
            elementGuidelines={Array.from(
              document.querySelectorAll("#template-container .selectable"),
            )}
            bounds={
              selectedElement?.tagName === "IMG" &&
              selectedElement?.src !== constants.QR_ICON
                ? {}
                : { left: 0, top: 0, right: 0, bottom: 0, position: "css" }
            }
            snapThreshold={5}
            verticalGuidelines={["0%", "50%", "100%"]}
            horizontalGuidelines={["0%", "50%", "100%"]}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default Canvas;
