import * as React from "react";
import {
  AppBar,
  Toolbar,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  return (
    <>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        style={{ zIndex: 100 }}
      >
        <Toolbar>
          <Grid
            container
            spacing={{ md: 2, xs: 0 }}
            alignItems="center"
            wrap="noWrap"
          >
            <Grid item xs={5} sm={4}>
              <Logo
                onClick={() =>
                  navigate(
                    `${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=SENT`,
                  )
                }
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  maxWidth: "134px",
                  maxHeight: "32px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <Stack
                direction="row"
                ml={{ xs: 1 }}
                spacing={{ md: 1, xs: 0 }}
                alignItems="center"
                mr={-1}
              >
                <ProfileMenu />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Divider />
    </>
  );
}
